import React from 'react';
import PropTypes from 'prop-types';
import Account from "./account/account";

const Finance = props => {
	return (
		<div>
			<Account/>
		
		</div>
	);
};

Finance.propTypes = {

};

export default Finance;
